@mainDarkColor: #858a9f;
@mainbackgroundColor: #5d637c;
@fabColor: #8ac175;
@archivedColor: #ffc0cb;

h2 {
    color: @navDarkColor;
}

.miles-card h4,
.miles-form h4,
.miles-list-card h4 {
    color: @mainDarkColor;
}

.miles-card.padded {
    padding-bottom: 30px;
}

.small-font {
    font-size: 14px;
}

.skin-blue .main-header li.user-header {
    background-color: @mainbackgroundColor;
}

tr.archived > td {
    background-color: @archivedColor !important;
}

.floatLeft {
    text-align: left;
}

/*
menu-img .fa .fa-fw {
    color: @fabColor;
}
*/

.formattedBlock {
    display: block;
    padding: 9.5px;
    margin: 10px 0 10px;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.scrollable-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    border-color: #aaa;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
}

.scrollable-table tbody {
    display: block;
    max-height: 500px;
    overflow-y: auto;
}

.scrollable-table thead,
.scrollable-table tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.scrollable-table td {
    overflow-wrap: break-word;
}

.PadL5 {
    padding-left: 5px;
}

.PadL16 {
    padding-left: 16px;
}

.marginT5 {
    margin-top: 5px;
}

.marginB0 {
    margin-bottom: 0px;
}

.marginB5 {
    margin-bottom: 5px;
}

.marginB10 {
    margin-bottom: 10px;
}
.marginB15 {
    margin-bottom: 15px;
}

.marginL20 {
    margin-left: 20px;
}
.marginL25 {
    margin-left: 25px;
}
.marginL40 {
    margin-left: 40px;
}
.marginL45 {
    margin-left: 45px;
}
.marginVertical5 {
    margin: 5px 0;
}

.marginVerticalAuto {
    margin: auto 0;
}

.date-time-requested-wrapper {
    border: 1px solid #ccc;
    padding: 4px 4px 0px 10px;
    border-radius: 3px;
}

.vertical-align-top {
    vertical-align: top;
}

.btn-remove-color {
    color: #e54b4b;
}

.btn-remove {
    font-size: 16px;
    background-color: white;
}

.top-negative45 {
    top: -45px;
}

.archive {
    background-color: #ffc0cb;
}

.panel-default > .panel-heading {
    color: #fff;
    background-color: @mainDarkColor;
    border-color: @mainDarkColor;
}

.font-15 {
    font-size: 15px;
}

.no-padding {
    padding: 0 !important;
}

.paddingVertical0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.hidden {
    display: hidden !important;
}

.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.notes-column,
.notes-column * {
    font-size: 14px !important;
    margin: 0px;
    font-family: Roboto, 'Helvetica Neued3d3d3d3d3d3', sans-serif !important;
}

.expanded-editor .cke_contents {
    min-height: 550px;
}

body.swal2-shown {
  overflow-y: auto !important;
}

.modal{
    z-index: 1060 !important;
}

.swal2-html-container{
    overflow: visible !important;
}

.action-col {
    display: flex;
    > div {
        flex: 1 1 0px;
    }
}
