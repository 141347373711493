@import 'styles/nav';
@import 'styles/buttons';
@import 'styles/base';
@import 'styles/ng-select';
@import 'styles/imedview';
@import '../../node_modules/@angular/cdk/overlay-prebuilt.css';
/* You can add global styles to this file, and also import other style files */
//
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

@-webkit-keyframes background_strobe_overdue_task {
    from {
        background-color: violet;
        border-color: white;
    }
    to {
        background-color: purple;
        border-color: rgb(51, 51, 51);
    }
}
@-moz-keyframes background_strobe_overdue_task {
    from {
        background-color: violet;
        border-color: white;
    }
    to {
        background-color: purple;
        border-color: rgb(51, 51, 51);
    }
}
@-ms-keyframes background_strobe_overdue_task {
    from {
        background-color: violet;
        border-color: white;
    }
    to {
        background-color: purple;
        border-color: rgb(51, 51, 51);
    }
}
@-o-keyframes background_strobe_overdue_task {
    from {
        background-color: violet;
        border-color: white;
    }
    to {
        background-color: purple;
        border-color: rgb(51, 51, 51);
    }
}
@keyframes background_strobe_overdue_task {
    from {
        background-color: violet;
        border-color: white;
    }
    to {
        background-color: purple;
        border-color: rgb(51, 51, 51);
    }
}
@keyframes background_strobe_client_portal_document_queue {
    from {
        background-color: green;
        border-color: white;
    }
    to {
        background-color: darkolivegreen;
        border-color: rgb(51, 51, 51);
    }
}

@keyframes background_strobe_claim_request_document_queue {
    from {
        background-color: rgb(17, 182, 233);
        border-color: white;
    }
    to {
        background-color: rgb(14, 136, 173);
        border-color: rgb(187, 187, 187);
    }
}

tr.claimRequestForm,
div.claimRequestForm {
    -webkit-animation: background_strobe_claim_request_document_queue 1s infinite alternate;
    -moz-animation: background_strobe_claim_request_document_queue 1s infinite alternate;
    -ms-animation: background_strobe_claim_request_document_queue 1s infinite alternate;
    -o-animation: background_strobe_claim_request_document_queue 1s infinite alternate;
    animation: background_strobe_claim_request_document_queue 1s infinite alternate;
    color: white;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
}

div.clientPortalTask .fa {
    color: white;
}

tr.green,
div.green {
    background-color: #8ac175 !important;
    color: rgb(51, 51, 51) !important;
}

.green {
    color: #8ac175 !important;
}

div.green .fa {
    color: rgb(51, 51, 51);
}

tr.cyan,
div.cyan {
    background-color: cyan !important;
    color: rgb(51, 51, 51);
}

tr.yellow,
div.yellow {
    background-color: #ffd700 !important;
    color: rgb(51, 51, 51);
}
div.yellow .fa {
    color: rgb(51, 51, 51);
}

tr.red,
div.red {
    background-color: crimson !important;
    color: white;
}
div.red .fa {
    color: white;
}

.table-striped > tbody > tr.red td,
.table-striped > tbody > tr.purple td,
.table-striped > tbody > tr.cyan td,
.table-striped > tbody > tr.green td,
.table-striped > tbody > tr.hotpink td,
.table-striped > tbody > tr.yellow td {
    background-color: inherit;
    transition: none;
}

tr.purple,
div.purple.task-row,
div.purple,
div.purple td {
    -webkit-animation: background_strobe_overdue_task 1s infinite alternate;
    -moz-animation: background_strobe_overdue_task 1s infinite alternate;
    -ms-animation: background_strobe_overdue_task 1s infinite alternate;
    -o-animation: background_strobe_overdue_task 1s infinite alternate;
    animation: background_strobe_overdue_task 1s infinite alternate;
    color: white;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
}
div.purple .fa {
    color: white;
}

tr.clientPortalTask,
div.clientPortalTask {
    -webkit-animation: background_strobe_client_portal_document_queue 1s infinite alternate;
    -moz-animation: background_strobe_client_portal_document_queue 1s infinite alternate;
    -ms-animation: background_strobe_client_portal_document_queue 1s infinite alternate;
    -o-animation: background_strobe_client_portal_document_queue 1s infinite alternate;
    animation: background_strobe_client_portal_document_queue 1s infinite alternate;
    color: white;
    border-style: solid;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
}
div.clientPortalTask .fa {
    color: white;
}

.taskList {
    padding: 10px 25px 10px 15px;
    font-size: 12px;
    color: #858a9f;
    background: #0f1016;
}

.user-or-user-role .btn-primary-inverse {
    background-color: white;
}
.input-border {
    border: 1px solid red;
}

.dropup .dropdown-menu {
    bottom: auto;
}
html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neued3d3d3d3d3d3', sans-serif;
}

ul.show {
    overflow-y: auto;
    max-height: 50vh;
}

@-webkit-keyframes highlight-high-value {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #8ac175;
    }
}
@-moz-keyframes highlight-high-value {
    from {
        background-color: #d3d3d3;
    }
    to {
        background-color: #8ac175;
    }
}
@-o-keyframes highlight-high-value {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #8ac175;
    }
}
@keyframes highlight-high-value {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #8ac175;
    }
}

tr.high-value-service > td {
    -webkit-animation: highlight-high-value 2s infinite alternate; /* Safari 4+ */
    -moz-animation: highlight-high-value 2s infinite alternate; /* Fx 5+ */
    -o-animation: highlight-high-value 2s infinite alternate; /* Opera 12+ */
    animation: highlight-high-value 2s infinite alternate; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes highlight-rush {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #ffd700;
    }
}
@-moz-keyframes highlight-rush {
    from {
        background-color: #d3d3d3;
    }
    to {
        background-color: #ffd700;
    }
}
@-o-keyframes highlight-rush {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #ffd700;
    }
}
@keyframes highlight-rush {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #ffd700;
    }
}

tr.rush-service > td {
    -webkit-animation: highlight-rush 2s infinite alternate; /* Safari 4+ */
    -moz-animation: highlight-rush 2s infinite alternate; /* Fx 5+ */
    -o-animation: highlight-rush 2s infinite alternate; /* Opera 12+ */
    animation: highlight-rush 2s infinite alternate; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes highlight-high-value-rush {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #ff00ff;
    }
}
@-moz-keyframes highlight-high-value-rush {
    from {
        background-color: #d3d3d3;
    }
    to {
        background-color: #ff00ff;
    }
}
@-o-keyframes highlight-high-value-rush {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #ff00ff;
    }
}
@keyframes highlight-high-value-rush {
    from {
        background-color: #ffffff;
    }
    to {
        background-color: #ff00ff;
    }
}

tr.high-value-rush-service > td {
    -webkit-animation: highlight-high-value-rush 2s infinite alternate; /* Safari 4+ */
    -moz-animation: highlight-high-value-rush 2s infinite alternate; /* Fx 5+ */
    -o-animation: highlight-high-value-rush 2s infinite alternate; /* Opera 12+ */
    animation: highlight-high-value-rush 2s infinite alternate; /* IE 10+, Fx 29+ */
}

.physician-font {
    font-size: 16px;
    font-weight: bold;
}

.heading-with-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.document-card-modal {
    z-index: 10000;
}

.document-card-modal .modal-dialog {
    width: 90vw;
}

.document-card-modal .modal-content {
    margin-top: -7%;
}

.film-modal .modal-dialog {
    width: 700px;
}

.film-modal .modal-content {
    padding: 5px;
}

.film-modal .scrollable-table,
.film-modal .table {
    margin-bottom: 0;
}

.details-list {
    list-style-type: none;
    display: inline-block;
    vertical-align: top;
}

.cdk-overlay-container {
    z-index: 9999;
}

.expanding-fab a.btn-fab-md {
    span {
        span {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            font-family: monospace;
            width: 1ch;
            position: relative;
        }
    }
    &:hover {
        width: auto;
        span {
            span {
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                font-family: Roboto, 'Helvetica Neued3d3d3d3d3d3', sans-serif;
                width: auto;
                position: relative;
            }
        }
    }
}

.swal2-modal .swal2-content {
    padding: 0;
}

.modal-hide-actions .swal2-actions {
    display: none;
}
span.empty-currency {
    padding-right: 3em;
}

body > .swal2-container .swal2-popup {
    font-size: 1.35rem !important;
}

.reportDueBy {
    color: #c23336;
    font-size: 16px;
    font-weight: bold;
}

.swal-ambra {
    background-color: #f4f4f4 !important;
    color: #444 !important;
}

// give hotpink colot to the physician/expert port tasks
tr.hotpink,
div.hotpink {
    background-color: rgb(242, 9, 180) !important;
    color: rgb(51, 51, 51);
}

